import i18n from 'i18next';
import { get as lodashGet, isEmpty, map, toString } from 'lodash';
import { devtools } from 'zustand/middleware';

import { DashboardVehicle } from '../pages/DashboardGaragePage/DashboardVehicle';
import useApiStore from './apiStore';
import { create, storeDevToolOptions } from './storeManager';

type DashboardStateComputed = {
  vehiclesLoading: boolean;
};

type DashboardState = {
  fetchVehicleByIdFailedMessage: string | null;
  fetchVehiclesFailedMessage: string | null;
  fetchVehiclesSuccess: boolean;
  isLoadingTransition: boolean;
  vehicle: DashboardVehicle | null;
  vehicles: DashboardVehicle[];

  computed: DashboardStateComputed;

  addVehicle(vehicle: DashboardVehicle | null): void;
  fetchVehicleById(vehicleId: string | undefined): Promise<void> | undefined;
  fetchVehicles(): Promise<void>;
};

const useDashboardStore = create()(
  devtools<DashboardState>(
    (set, get) => ({
      fetchVehicleByIdFailedMessage: null,
      fetchVehiclesFailedMessage: null,
      fetchVehiclesSuccess: false,
      isLoadingTransition: false,
      vehicle: null,
      vehicles: [],

      computed: {
        get vehiclesLoading() {
          const { vehicles, isLoadingTransition } = get();

          return isEmpty(vehicles) && isLoadingTransition;
        },
      },

      addVehicle(vehicle: DashboardVehicle | null) {
        if (!vehicle) {
          return;
        }

        const { vehicles } = get();

        set({ vehicles: [new DashboardVehicle(vehicle), ...vehicles] });
      },
      fetchVehicleById(vehicleId: string | undefined) {
        if (!vehicleId) {
          return;
        }

        set({
          fetchVehicleByIdFailedMessage: null,
          isLoadingTransition: true,
          vehicle: null,
        });

        const radixHttpRequest = useApiStore.getState().radixApi();

        return radixHttpRequest
          .get<never, DashboardVehicle>(`/2.0/vehicles/${vehicleId}`)
          .then((vehicle) => set({ vehicle: new DashboardVehicle(vehicle) }))
          .catch((error) => {
            const errorStatus = toString(lodashGet(error, 'response.status'));

            const defaultMessage = i18n.t(
              'errors.fetchVehicleById.defaultMessage',
            );

            set({
              fetchVehicleByIdFailedMessage: lodashGet(
                i18n.t('errors.fetchVehicleById.statusMessage'),
                errorStatus,
                defaultMessage,
              ),
            });
          })
          .finally(() => set({ isLoadingTransition: false }));
      },
      fetchVehicles() {
        const radixHttpRequest = useApiStore.getState().radixApi();

        set({
          fetchVehiclesFailedMessage: null,
          fetchVehiclesSuccess: false,
          isLoadingTransition: true,
        });

        return radixHttpRequest
          .get<never, DashboardVehicle[]>('/2.0/vehicles')
          .then((vehicles) =>
            map(vehicles, (vehicle) => new DashboardVehicle(vehicle)),
          )
          .then((vehicles) => set({ fetchVehiclesSuccess: true, vehicles }))
          .catch((error) => {
            const errorStatus = toString(lodashGet(error, 'response.status'));

            const defaultMessage = i18n.t(
              'errors.fetchVehicles.defaultMessage',
            );

            set({
              fetchVehiclesFailedMessage: lodashGet(
                i18n.t('errors.fetchVehicles.statusMessage'),
                errorStatus,
                defaultMessage,
              ),
            });
          })
          .finally(() => set({ isLoadingTransition: false }));
      },
    }),
    storeDevToolOptions('dashboardStore'),
  ),
);

export default useDashboardStore;
