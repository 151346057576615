import { merge } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Workflow } from '../../../../constants/Workflow';
import { populateFields } from '../../../../forms/populateFields';
import { useAPI } from '../../../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useModal } from '../../../../hooks/useModal/useModal';
import { useWorkflowPathname } from '../../../../hooks/useWorkflowPathname/useWorkflowPathname';
import { withMatchMedia } from '../../../../providers/context/MatchMediaProvider/withMatchMedia';
import { additionalIncomeFields as checklightIncomeFields } from '../../../../utils/helpers/income/checklight';
import { additionalIncomeFields as unifiedIncomeFields } from '../../../../utils/helpers/income/unified';
import { FormConfirmModal } from '../../../FormConfirmModal/FormConfirmModal';
import { ConfirmModal } from '../../../shared/confirmModal/ConfirmModal';
import { Form } from '../../../shared/Form/Form';
import { FormContainer } from './styles';

const fields: Record<string, any> = {
  [Workflow.checklight]: checklightIncomeFields,
  [Workflow.unified]: unifiedIncomeFields,
};

const EditAdditionalIncomeComponent = ({
  isDesktop,
}: {
  isDesktop?: boolean;
}) => {
  const location = useLocation();
  const {
    state: { id: incomeId },
  }: any = location;
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();
  const [removeModalConfirmed, setRemoveModalConfirmed] = useState(false);
  const removeModal = useModal();
  const api = useAPI();
  const navigate = useNavigate();
  const methods = useForm();
  const { incomes: { additional: allIncomes = [] } = {} } = currentUserContext;
  const editedIncome = useMemo(
    () => allIncomes.find(({ id }: any) => id === incomeId),
    [allIncomes, incomeId],
  );
  const root = useWorkflowPathname();
  const additionalIncomeFields = fields[root];

  const updateUserData = useAsyncCallback((updatedIncome) => {
    return api
      .put('/users/current', {
        incomes: { ...currentUserContext.incomes, additional: updatedIncome },
      })
      .then((user) => {
        setCurrentUserContext({
          ...currentUserContext,
          ...user,
        });
        navigate('..');
      });
  });

  const updateIncome = useCallback(
    async (data: any) => {
      const updatedData = allIncomes.map((income) => {
        if (income.id === editedIncome?.id) {
          return {
            ...income,
            ...data,
          };
        }
        return income;
      });
      await updateUserData.execute(updatedData);
    },
    [editedIncome, allIncomes, updateUserData],
  );

  const removeAdditionalModal = useMemo(() => {
    const onConfirm = async () => {
      setRemoveModalConfirmed(true);

      const updatedData = allIncomes.filter(({ id }) => id !== incomeId);
      await updateUserData.execute(updatedData);
      removeModal.close();
    };

    return removeModal.isShowing ? (
      <ConfirmModal
        btnText="Remove"
        title="Are you sure you want to remove the income?"
        onCancel={removeModal.close}
        onConfirm={onConfirm}
      >
        Removing this item from your dashboard will delete it, and all of its
        records. Are you sure you want to continue?
      </ConfirmModal>
    ) : null;
  }, [removeModal, updateUserData, allIncomes, incomeId]);

  const submitBtn = useMemo(
    () => ({
      handler: updateIncome,
      label: 'Save',
      testId: 'nameSave',
      isDisabled: updateUserData.loading,
    }),
    [updateUserData, updateIncome],
  );

  const removeBtn = useMemo(
    () => ({
      handler: removeModal.open,
      label: 'Remove',
      testId: 'nameRemove',
      isDisabled: updateUserData.loading,
    }),
    [removeModal, updateUserData],
  );

  const incomeForm = useMemo(() => {
    return {
      fields: merge(
        {},
        additionalIncomeFields,
        populateFields(additionalIncomeFields, editedIncome),
        {
          amount: {
            validationRules: {
              required: 'Amount is required',
              min: {
                value: 1,
                message: 'You should have some income',
              },
            },
          },
        },
      ),
      actions: {
        primary: {
          ...submitBtn,
        },
        secondary: {
          ...removeBtn,
        },
      },
      globalErrors: updateUserData.error,
    };
  }, [
    removeBtn,
    submitBtn,
    editedIncome,
    updateUserData.error,
    additionalIncomeFields,
  ]);

  return (
    <FormContainer $isDesktop={isDesktop}>
      <Form form={incomeForm} methods={methods} />
      {removeAdditionalModal}
      <FormConfirmModal
        disallowModal={removeModalConfirmed}
        formFields={incomeForm.fields}
        methods={methods}
      />
    </FormContainer>
  );
};
export const EditAdditionalIncome = withMatchMedia(
  EditAdditionalIncomeComponent,
);
