import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useDashboardStore from '../store/dashboardStore';
import { DEFAULT_AUTH_PATH } from '../store/routeStore';
import useWorkflowStore, { Workflow } from '../store/workflowStore';

/**
 * WorkflowGuard
 *
 * Handles the initializations required for a workflow which includes things like:
 * - Fetching any prerequisite data required for the workflow
 * - Detecting whether the current route is part of the workflow
 * - Redirecting the user to the correct page if they try to access a page they should not
 */
const WorkflowGuard = ({
  children,
  workflow,
}: {
  children: JSX.Element;
  workflow: Workflow;
}) => {
  const { vehicleId } = useParams();

  const [routeOverride, setRouteOverride] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const handleInitialization = async () => {
    // Handle prerequisites for the workflow
    if (workflow === Workflow.APPLICANT) {
      // applicant workflow routes contain a vehicle id in the path
      // we fetch the vehicle before initializing the workflow so that the WorkflowPage
      // can access the vehicle data for defining its routes (applicant.ts).
      if (vehicleId && vehicleId !== useDashboardStore.getState().vehicle?.id) {
        await useDashboardStore.getState().fetchVehicleById(vehicleId);
      }

      // if the vehicle fetch fails, we redirect the user to the default authenticated path (dashboard)
      if (useDashboardStore.getState().fetchVehicleByIdFailedMessage) {
        navigate(DEFAULT_AUTH_PATH);
      }
    }

    // Initialize - detect whether the current route is part of the workflow
    useWorkflowStore.getState().detect(location.pathname, setRouteOverride);
  };

  useEffect(() => {
    handleInitialization();
  }, [location]);

  useEffect(() => {
    if (routeOverride) {
      // the route override can be used by the workflow store to inform
      // this guard about the user attempting to access a page that should
      // not be accessed yet because there are incomplete steps
      //
      // using `replace` here so the user is unable to go back to the
      // previous page as we have already determined they have not completed
      // enough steps
      navigate(routeOverride, { replace: true });

      // reset the override value after we navigate
      setRouteOverride('');
    }
  }, [routeOverride, navigate]);

  return children;
};

export default WorkflowGuard;
